import { Link } from 'gatsby'
import React from 'react'
import { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeaderIndex from '../components/headerIndex'
import FooterIndex from '../components/footerIndex'

import trainingImage from '../images/training.svg'
import mediationImage from '../images/mediation.svg'
import moderationImage from '../images/moderation.svg'
import RuthImage from '../components/ruthImage'
import FranziImage from '../components/franziImage'
import { device } from '../styles/devices'

import pdf from '../assets/Konfliktrezept-Angebot.pdf'

const moderationContent = (
  <ul>
    <li>Strukturierter Austausch</li>
    <li>Workshops</li>
    <li>Teammoderation</li>
    <li>Konfliktgespräche</li>
  </ul>
)

const trainingContent = (
  <ul>
    <li>Kommunikationsworkshop</li>
    <li>Teambuilding</li>
    <li>Führungskräftetraining</li>
    <li>Kompetenzworkshop</li>
  </ul>
)

const mediationContent = (
  <ul>
    <li>Mediation</li>
    <li>Konfliktberatung</li>
    <li>Mediative Teamentwicklung</li>
    <li>Konfliktmanagementsystem</li>
  </ul>
)

const IndexPage = () => (
  <Layout isIndexPage={true}>
    <SEO />
    <HeaderIndex
      contentRender={() => (
        // <>
        //   Konfliktrezept erweitert Ihr kommunikatives Toolkit, öffnet Ihren
        //   Blick für neue Perpektiven, mixt bewährte Kommunikationstheorien und
        //   -konzepte mit modernen Arbeitsmethoden.
        // </>
        // <p>
        //   Wir sind ein junges und dynamisches Mediatorinnen-Duo aus der
        //   Metropolregion Berlin. Unser Ziel ist es, Ihre Mitarbeiterinnen und
        //   Mitarbeiter zu motivieren an einem Strang zu ziehen und ihre
        //   Projekte effizienter zu gestalten. Unsere Kunden profitieren von
        //   einer produktiven Zusammenarbeit, zufriedenen Mitarbeitern und einem
        //   zukunftsfähigen Team.
        // </p>
        <div
          css={css`
            & {
              /* width: 500px; */
            }
          `}
        >
          <h2
            css={css`
              & {
                margin-top: 6rem;
                margin-bottom: 6rem;
                color: #ffe285;
                font-family: 'Montserrat';
                font-weight: bold;
                font-size: 5rem;
                line-height: 4.5rem;
              }
              @media ${device.mobileM} {
                font-size: 6.5rem;
                line-height: 6rem;
              }
            `}
          >
            Mediation <br />
            neu gedacht.
          </h2>
          {/* <p>Wir sind zwei Mediatorinnen aus Berlin.</p>
          <p>Wir sind jung, dynamisch & stehen für innovative Methoden.</p>
          <p>
            Sie profitieren von zufriedenen Mitarbeitern, einem zukunftsfähigen
            Team und produktiver Zusammenarbeit.
          </p> */}
          <p>
            Konfliktrezept ist eine Toolbox, mit der Sie lernen besser mit
            Konflikten am Arbeitsplatz umzugehen.
          </p>
          <p>
            Kommunikationskonzepte mit modernen Arbeitsmethoden - immer mit
            mediativer Haltung.
          </p>
          <p>
            Denn klar ist, zufriedene Mitarbeiter, ein zukunftsfähiges Team und
            eine produktive Zusammenarbeit sind der Kern jeden Erfolgs.
          </p>
          <p>Profitieren Sie von unserem Know-how.</p>
          <Link
            to="/kontakt"
            css={css`
              & {
                display: inline-block;
                margin-top: 6rem;
                padding: 1rem 3rem;
                background-color: #ffe285;
                border-radius: 2rem;
                color: #000000bb;
              }
            `}
          >
            Kontaktieren Sie uns
          </Link>
          {/* <a
            href={pdf}
            css={css`
              & {
                display: inline-block;
                margin-top: 6rem;
                margin-left: 1rem;
                padding: 1rem 3rem;
                background-color: #ffffff22;
                border-radius: 2rem;
              }
            `}
          >
            Krisenangebot!
          </a> */}
        </div>
      )}
    />
    <div className="grid">
      <div className="grid-contained">
        {/* Schwerpunkte */}
        {[
          {
            title: 'Mediation',
            img: mediationImage,
            content: mediationContent,
          },
          {
            title: 'Workshops',
            img: moderationImage,
            content: trainingContent,
          },
          // {
          //   title: 'Moderation',
          //   img: trainingImage,
          //   content: moderationContent,
          // },
        ].map(({ title, img, content }, i) => (
          <div
            key={title}
            css={css`
              & > img {
                margin-bottom: 30px;
              }

              & ul {
                list-style: none;
                text-align: center;
              }

              & li {
                font-size: 1.8rem;
                line-height: 1.75em;
              }

              ${i !== 0 &&
                `
                margin-top: 100px;
              `}

             

              ${i % 2 !== 0 &&
                `
                  @media ${device.tablet} {
                    flex-direction: row-reverse;
                  }
              `}

              @media ${device.tablet} {
                display: flex;
                justify-content: space-around;
                
                & ul {
                  margin-left: 1.6rem;
                  list-style: disc;
                  text-align: left;
                }


                & > img {
                  margin-bottom: 0;
                }
                
                & > img {
                  width: 350px;
                  height: 350px;
                  /* margin-right: 325px; */
                }
              }
            `}
          >
            <img src={img} />
            <div>
              <div
                css={css`
                  text-align: center;

                  @media ${device.tablet} {
                    text-align: left;
                  }
                `}
              >
                <h2
                  css={css`
                    display: inline-block;
                    position: relative;
                    font-size: 3.2rem;
                    margin-bottom: 40px;
                    background: linear-gradient(0, #fff1c6 24px, transparent 0);
                    &:before {
                      content: ' ';
                      position: absolute;
                      bottom: 0;
                      left: -18px;
                      background-color: transparent;
                      border-bottom: 24px solid transparent;
                      border-right: 18px solid #fff1c6;
                    }
                    &:after {
                      content: ' ';
                      position: absolute;
                      bottom: 0;
                      background-color: transparent;
                      border-top: 24px solid transparent;
                      border-left: 18px solid #fff1c6;
                    }
                  `}
                >
                  {title}
                </h2>
              </div>
              <div>{content}</div>
              <div
                css={css`
                  margin-top: 30px;
                  color: #3668ed;
                  font-size: 1.8rem;
                  text-align: center;

                  & span {
                    padding-left: 6px;
                    display: none;
                  }

                  & a:hover > span {
                    padding-left: 12px;
                  }

                  @media ${device.tablet} {
                    text-align: left;
                  }
                `}
              >
                <Link to={`/${title.toLowerCase()}`}>
                  Weitere Informationen <span>&#9658;</span>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="grid">
      <div className="grid-contained">
        {/* Team */}
        <section
          css={css`
            margin-top: 100px;
          `}
        >
          <div
            css={css`
              text-align: center;
            `}
          >
            <h2
              css={css`
                display: inline-block;
                position: relative;
                font-size: 3.2rem;
                margin-bottom: 40px;
                background: linear-gradient(0, #fff1c6 24px, transparent 0);
                &:before {
                  content: ' ';
                  position: absolute;
                  bottom: 0;
                  left: -18px;
                  background-color: transparent;
                  border-bottom: 24px solid transparent;
                  border-right: 18px solid #fff1c6;
                }
                &:after {
                  content: ' ';
                  position: absolute;
                  bottom: 0;
                  background-color: transparent;
                  border-top: 24px solid transparent;
                  border-left: 18px solid #fff1c6;
                }
              `}
            >
              Team
            </h2>
          </div>
          <div
            css={css`
              @media ${device.tablet} {
                display: flex;
                justify-content: space-around;
              }
            `}
          >
            {/* Ruth */}
            <div
              css={css`
                flex-grow: 1;
                text-align: center;
              `}
            >
              <div>
                <RuthImage />
              </div>
              <h2
                css={css`
                  margin-top: 18px;
                  font-size: 2.8rem;
                `}
              >
                Ruth Nießen
              </h2>
              <ul
                css={css`
                  margin-top: 8px;
                  font-size: 1em;
                  line-height: 1.75em;
                `}
              >
                <li>Mediatorin</li>
                <li>Sozialarbeiterin</li>
                <li>Nonprofit-Expertin</li>
                <li>Organisationsentwicklerin</li>
                <li>Changemanagerin</li>
                <li>
                  <a
                    href="http://ruthniessen.de"
                    rel="noopener noreferrer"
                    css={css`
                      display: block;
                      margin-top: 20px;
                      color: #3668ed;
                    `}
                  >
                    Webseite
                  </a>
                </li>
                <li>
                  <Link
                    to="/team/ruth"
                    css={css`
                      display: block;
                      margin-top: 20px;
                      color: #3668ed;
                    `}
                  >
                    Mehr über Ruth
                  </Link>
                </li>
              </ul>
            </div>

            {/* Franzi */}
            <div
              css={css`
                flex-grow: 1;
                text-align: center;
                margin-top: 100px;

                @media ${device.tablet} {
                  margin-top: 0;
                }
              `}
            >
              <div>
                <FranziImage />
              </div>
              <h2
                css={css`
                  margin-top: 18px;
                  font-size: 2.8rem;
                `}
              >
                Franziska Schacht
              </h2>
              <ul
                css={css`
                  margin-top: 8px;
                  font-size: 1em;
                  line-height: 1.75em;
                `}
              >
                <li>Mediatorin</li>
                <li>Linguistin</li>
                <li>Kommunikationswissenschaftlerin</li>
                <li>IT-Beraterin</li>
                <li>Prozessexpertin</li>
                <li>
                  <Link
                    to="/team/franziska"
                    css={css`
                      display: block;
                      margin-top: 20px;
                      color: #3668ed;
                    `}
                  >
                    Mehr über Franziska
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div
      className="grid"
      css={css`
        & {
          text-align: center;
        }
      `}
    >
      <div className="grid-contained">
        {/* Blog */}
        <section
          css={css`
            & {
              margin-top: 100px;
            }
          `}
        >
          <div>
            <h2
              css={css`
                & {
                  display: inline-block;
                  position: relative;
                  font-size: 3.2rem;
                  margin-bottom: 40px;
                  background: linear-gradient(0, #fff1c6 24px, transparent 0);
                  &:before {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: -18px;
                    background-color: transparent;
                    border-bottom: 24px solid transparent;
                    border-right: 18px solid #fff1c6;
                  }
                  &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    background-color: transparent;
                    border-top: 24px solid transparent;
                    border-left: 18px solid #fff1c6;
                  }
                }
              `}
            >
              Blog
            </h2>
          </div>
        </section>
        <div>
          <ul
            css={css`
              & a {
                padding: 0.25rem 0.5rem;
                border-bottom: 2px solid #3668ed;
                color: #3668ed;
              }

              & a:hover {
                color: #6d95ff;
                border-color: #6d95ff;
              }
            `}
          >
            <li>
              <Link to="/mediation-neu-gedacht">
                Mediation neu gedacht in 2020.
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <FooterIndex />
  </Layout>
  // <ParallaxProvider>
  //   <div
  //     css={css`
  //       position: absolute;
  //       top: 50vh;
  //       left: 50%;
  //       margin-left: -7rem;
  //       width: 14rem;
  //       height: auto;
  //       font-family: 'futura-pt', Futura, Helvetica, sans-serif;
  //       font-weight: bold;
  //       letter-spacing: 0.3rem;
  //       text-align: center;
  //       text-transform: uppercase;
  //       font-size: 3rem;
  //       color: #405d86;
  //     `}
  //   >
  //     <p>Scroll</p>
  //     <Parallax y={[-20, 20]} className="arrow-1">
  //       <svg version="1.1" x="0px" y="0px" viewBox="0 0 167 299">
  //         <polygon className="fill-1" points="137.4,0 83.5,145.9 29.6,0 " />
  //       </svg>
  //     </Parallax>
  //     <Parallax className="arrow-2" y={[-10, 1]}>
  //       <svg version="1.1" x="0px" y="0px" viewBox="0 0 167 299">
  //         <polygon className="fill-3" points="167,73 83.5,298.9 0,73 " />
  //       </svg>
  //     </Parallax>
  //   </div>
  // </ParallaxProvider>
)

export default IndexPage
